import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import Dotdotdot from "components/Common/Dotdotdot";
import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import InfoIcons from "components/Entities/Items/InfoIconsAsync";
import IgnoreErrorBoundary from "components/Errors/IgnoreErrorBoundary";
import RatingWithHover from "components/Rating/RatingWithHoverAsync";

import CardActions from "../CardActions";
import CardCategories from "../CardCategoriesAsync";
import CardCreators from "../CardCreatorsAsync";
import CardTitle from "../CardTitle";
import EpisodeEllipsisMenu from "./EpisodeEllipsisMenu";
import { getIcons } from "./sharedEpisodeElements";

import {
  EPISODE_CARD_HEIGHT_MOBILE,
  MOBILE_BOTTOM_ROW_HEIGHT,
} from "constants/podcast";
import { selectSpecificPodcast } from "selectors/podcast";
import cachedImage from "utils/entity/cachedImage";
import getEpisodeUrl from "utils/entity/getEpisodeUrl";
import getPodcastUrl from "utils/entity/getPodcastUrl";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import cardStyles, {
  cardImageSizes,
  cardSizes,
  noBorderCardStyles,
} from "styles/CardStyles";
import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  episodeMobileCard: {
    ...cardStyles.mobileCard,
  },
  episodeMobileCardNoPanel: {
    ...cardStyles.cardNoPanel,
  },
  podcastPre: {
    color: "#a6a6a6",
  },
  topRow: {
    ...cardStyles.mobileCardTopRow,
  },
  midRow: {
    display: "flex",
    flexDirection: "column",
    padding: "3px .75rem .8rem",
  },
  entityImageContainer: {
    alignSelf: "flex-start",
    position: "relative",
  },
  episodeImage: {
    ...cardStyles.mobileCardEntityImage,
  },
  episodeInfo: {
    ...cardStyles.mobileCardEntityInfo,
    minHeight: "3rem",
    justifyContent: "flex-start",

    [ScreenSizes.mdAndAbove]: {
      ...cardStyles.mobileCardEntityInfo[ScreenSizes.mdAndAbove],
      paddingRight: "1rem",
      minHeight: "5rem",
    },
  },
  episodeInfoIconRow: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "baseline",
    margin: 0,

    [ScreenSizes.mdAndAbove]: {
      flexDirection: "row",
    },
  },
  ratingsContainer: {
    margin: "0 .75rem 0.5rem -0.2rem",
    minWidth: "5.5rem",
    display: "flex",
    alignItems: "center",

    [ScreenSizes.mdAndAbove]: {
      marginBottom: "-0.2rem",
    },
    [ScreenSizes.xsAndBelow]: {
      marginBottom: "0.5rem",
    },
  },
  ratingsText: {
    ...gStyles.fontSemiBold,
    fontSize: "0.625rem",
    lineHeight: 1.3,
    marginTop: ".1rem",
    marginLeft: "0.4em",
  },
  episodeDescription: {
    ...cardStyles.mobileCardDescription,

    display: "-webkit-box",
    padding: 0,
    marginBottom: cardSizes.mobileStandardPadding,
  },
  episodeDescriptionTruncation: {
    width: "100%",
  },
  podcastLink: {
    ...gStyles.textEllipsis,
    margin: ".2rem 0 .8rem",
    color: colours.black,
    fontSize: ".75rem",
  },
  podcastLinkFrom: {
    ...gStyles.avalonMedium,
    color: "#5a616d",
  },
  bottomRow: {
    ...cardStyles.bottomRow,
  },
  bottomRowInsidePanel: {
    ...cardStyles.bottomRowInsidePanel,
  },
  airDate: {
    ...gStyles.fontSemiBold,
    color: "#bababa",
    fontSize: "11px",
  },
  actions: {
    ...cardStyles.cardActions,
  },
  shareContainer: {
    ...cardStyles.mobileShareContainer,
  },
};

const ratingStyles = {
  star: {
    marginLeft: ".15rem",
    marginRight: ".15rem",
  },
  starEmpty: {
    color: colours.stars.empty,
  },
  starFilled: {
    color: colours.stars.filled,
  },
};

const iconStyles = {
  iconContainer: {
    marginRight: ".75rem",
  },
};

const noBorderStyles = {
  ...noBorderCardStyles,
  episodeMobileCard: {
    ...noBorderCardStyles.card,
  },
  episodeInfo: {
    ...noBorderCardStyles.entityInfo,
  },
};

const PAGE_SIZE = 3;

const ACTIONS = [
  { type: "listen" },
  { type: "bookmark" },
  { type: "list" },
  { type: "rate" },
  {
    type: "play",
    asButton: true,
    noLabel: true,
  },
];

const EpisodeMobileCard = (props) => {
  const {
    episode,
    onRateButtonClick,
    showCreators,
    showCategories,
    noPanel,
    noBorder,
    buttons,
    actions: passedActions,
    hideBottomRow,
    hideInfoIcons,
    hidePodcast,
    hideRating,
    hideEmptyRating,
    insidePanel,
    hideSecondaryButtons,
    showEllipsisMenu,
    showShare,
    hideIcons,
    hoverableStars,
    clickableStars,
    renderEntityImageOverlay,
    hidePowerScore,
    renderOnMount,
    deferCard,
  } = props;

  const { styles } = useStyles([baseStyles, noBorder && noBorderStyles], props);

  const podcast = useReduxState(
    (state) =>
      selectSpecificPodcast(state, episode && episode.get("podcast_id")),
    [episode]
  );
  const podcastUrl = podcast && getPodcastUrl(podcast);
  const episodeWithPodcast = useMemo(
    () => episode.set("podcast", podcast),
    [podcast, episode]
  );
  const episodeUrl = getEpisodeUrl(episodeWithPodcast);

  const icons = useMemo(() => {
    const iconConfigs = getIcons(episodeWithPodcast);

    if (hideIcons && hideIcons.length > 0) {
      return iconConfigs.filter(
        (config) => config && !hideIcons.includes(config.type)
      );
    }

    return iconConfigs;
  }, [episodeWithPodcast, hideIcons]);

  const handleRate = useMemo(
    () => onRateButtonClick || (() => null),
    [onRateButtonClick]
  );

  const renderIconRow = () => (
    <div className={css(styles.episodeInfoIconRow)}>
      {!hideRating &&
        (!hideEmptyRating ||
          (hideEmptyRating && episode.get("rating_count") > 0)) && (
          <div className={css(styles.ratingsContainer)}>
            <RatingWithHover
              onClick={handleRate}
              readonly={!onRateButtonClick}
              id={episode.get("id")}
              quiet
              rating={episode.get("rating")}
              numRatings={episode.get("rating_count")}
              fontSize=".75rem"
              styles={ratingStyles}
              entity={episode}
              entity_type="episode"
              hoverableStars={hoverableStars}
              clickableStars={clickableStars}
              ariaLabelFunc={({ rating }) =>
                `Community Rating: ${Math.round(rating * 100) / 100} out of 5`
              }
            />
            {episode.get("rating_count") > 0 && (
              <div className={css(styles.ratingsText)}>
                {episode.get("rating_count")}
              </div>
            )}
          </div>
        )}
      <InfoIcons icons={icons} styles={iconStyles} />
    </div>
  );

  const renderTopRow = () => (
    <div className={css(styles.topRow)}>
      <Link
        to={episodeUrl}
        className={css(styles.entityImageContainer)}
        aria-label={episode.get("title")}
      >
        <img
          className={css(styles.episodeImage)}
          src={cachedImage(
            (episode && episode.get("image_url")) ||
              (podcast && podcast.get("image_url")),
            cardImageSizes.mobileCardEntity
          )}
          alt={episode.get("title")}
          title={episode.get("title")}
          width={cardImageSizes.mobileCardEntity}
          height={cardImageSizes.mobileCardEntity}
        />
        {renderEntityImageOverlay && renderEntityImageOverlay(episode)}
      </Link>
      <div className={css(styles.episodeInfo)}>
        <CardTitle
          entity={episode}
          entityType="episode"
          hideSecondaryButtons={showEllipsisMenu || hideSecondaryButtons}
          podcast={podcast}
          secondaryEntity={hidePodcast ? null : podcast}
          secondaryEntityType="podcast"
          showHasGuests
          hidePowerScore={hidePowerScore}
          inlineContent={
            showEllipsisMenu && (
              <LazyLoadComponent renderOnMount={renderOnMount} height={25}>
                <div>
                  <EpisodeEllipsisMenu
                    episode={episode}
                    podcast={podcast}
                    showShare={showShare}
                    mobile
                  />
                </div>
              </LazyLoadComponent>
            )
          }
          renderOnMount={renderOnMount}
        />

        {!hideInfoIcons && renderIconRow()}
      </div>
    </div>
  );

  const renderCreators = () => (
    <div className={css(styles.bottomRowCategories)}>
      <CardCreators
        entity_type="episode"
        entity={episodeWithPodcast}
        ids={episode.get("creator_summary")}
        total={episode.get("creator_count")}
      />
    </div>
  );

  const renderBottomRow = () => (
    <LazyLoadComponent
      renderOnMount={renderOnMount}
      height={MOBILE_BOTTOM_ROW_HEIGHT}
    >
      <div
        className={css(
          styles.bottomRow,
          insidePanel && styles.bottomRowInsidePanel
        )}
      >
        {showCreators &&
          episode?.get("creator_summary")?.size > 0 &&
          renderCreators()}
        {showCategories && episode?.get("categories")?.size > 0 && (
          <CardCategories
            entity={episode}
            entityLink={podcastUrl}
            pageSize={PAGE_SIZE}
          />
        )}
        <div className={css(styles.actions)}>
          {buttons || (
            <CardActions
              actions={passedActions || ACTIONS}
              entity={episode}
              entity_type="episode"
              mobile
            />
          )}
        </div>
      </div>
    </LazyLoadComponent>
  );

  const renderCard = () => (
    <IgnoreErrorBoundary>
      <div
        data-id="episode-mobile-card"
        className={css(
          styles.episodeMobileCard,
          noPanel && styles.episodeMobileCardNoPanel,
          styles.entityCard
        )}
      >
        {renderTopRow()}
        <div className={css(styles.episodeDescription)}>
          <div className={css(styles.episodeDescriptionTruncation)}>
            <Dotdotdot
              clamp={hidePodcast && episode.get("title").length < 50 ? 4 : 3}
              tagName="div"
            >
              {episode.get("description")}
            </Dotdotdot>
          </div>
        </div>
        {!hideBottomRow && renderBottomRow()}
      </div>
    </IgnoreErrorBoundary>
  );

  if (deferCard) {
    return (
      <LazyLoadComponent height={EPISODE_CARD_HEIGHT_MOBILE}>
        {renderCard()}
      </LazyLoadComponent>
    );
  }

  return renderCard();
};

EpisodeMobileCard.propTypes = {
  episode: PropTypes.instanceOf(Map),
  onRateButtonClick: PropTypes.func,
  showCreators: PropTypes.bool,
  showCategories: PropTypes.bool,
  noPanel: PropTypes.bool,
  buttons: PropTypes.node,
  actions: PropTypes.array,
  hideBottomRow: PropTypes.bool,
  hideInfoIcons: PropTypes.bool,
  hideRating: PropTypes.bool,
  hideEmptyRating: PropTypes.bool,
  insidePanel: PropTypes.bool,
  hideSecondaryButtons: PropTypes.bool,
  showEllipsisMenu: PropTypes.bool,
  showShare: PropTypes.bool,
  hideIcons: PropTypes.array,
  hoverableStars: PropTypes.bool,
  clickableStars: PropTypes.bool,
  hidePodcast: PropTypes.bool,
  renderEntityImageOverlay: PropTypes.func,
  noBorder: PropTypes.bool,
  hidePowerScore: PropTypes.bool,
  renderOnMount: PropTypes.bool,
  deferCard: PropTypes.bool,
};

EpisodeMobileCard.defaultProps = {
  episode: null,
  onRateButtonClick: null,
  showCreators: false,
  showCategories: false,
  noPanel: false,
  insidePanel: false,
  buttons: null,
  actions: null,
  hideBottomRow: false,
  hideInfoIcons: false,
  hideRating: false,
  hideEmptyRating: true,
  hideSecondaryButtons: false,
  showEllipsisMenu: false,
  showShare: false,
  hideIcons: null,
  hoverableStars: false,
  clickableStars: false,
  hidePodcast: false,
  renderEntityImageOverlay: null,
  noBorder: false,
  hidePowerScore: false,
  renderOnMount: false,
  deferCard: false,
};

export default EpisodeMobileCard;
