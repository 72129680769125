import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo } from "react";

import BrandEllipsisMenu from "./BrandCards/BrandEllipsisMenuAsync";
import CreatorEllipsisMenu from "./CreatorCards/CreatorEllipsisMenuAsync";
import EpisodeEllipsisMenu from "./EpisodeCards/EpisodeEllipsisMenuAsync";
import NetworkEllipsisMenu from "./NetworkCards/NetworkEllipsisMenuAsync";
import PodcastEllipsisMenu from "./PodcastCards/PodcastEllipsisMenuAsync";

const EntityEllipsisMenu = (props) => {
  const { entity_type, entity } = props;

  switch (entity_type) {
    case "episode":
      return <EpisodeEllipsisMenu episode={entity} {...props} />;
    case "podcast":
      return <PodcastEllipsisMenu podcast={entity} {...props} />;
    case "brand":
      return <BrandEllipsisMenu brand={entity} {...props} />;
    case "creator":
      return <CreatorEllipsisMenu creator={entity} {...props} />;
    case "network":
      return <NetworkEllipsisMenu network={entity} {...props} />;
    default:
      return null;
  }
};

EntityEllipsisMenu.propTypes = {
  entity_type: PropTypes.string.isRequired,
  entity: PropTypes.instanceOf(Map),
};

EntityEllipsisMenu.defaultProps = {
  entity: null,
};

export default memo(EntityEllipsisMenu);
